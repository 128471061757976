<template>
  <div class="h-full w-full">
    <Wrapper class="max-w-xl">
        <div v-if="type === 'registration'" class="w-4/5 mx-auto">
            <h2 class="mb-3 text-2xl text-blue-dark"><b>Su inconformidad fue registrada</b></h2>
            <p class="mb-5 text-sm">El código asignado para hacerle seguimiento es:</p>
            <h2 class="mb-5 text-3xl text-blue-dark"><b>{{complaintCode}}</b></h2>
            <a-button @click="$router.push('/dashboard/complaints/search')" type="primary" shape="round" :block="true" >
              VOLVER AL INICIO
            </a-button>
        </div>
        <div v-if="type == 1 || type == 2" class="w-4/5 mx-auto">
          <h2 v-if="type == 1 " class="mb-3 text-2xl text-blue-dark"><b>La réplica fue registrada</b></h2>
          <h2 v-if="type == 2 " class="mb-3 text-2xl text-blue-dark"><b>Su queja fue presentada al Defensor del Consumidor Financiero</b></h2>
          <p class="my-5 text-sm">El código asignado para el seguimiento de su réplica es:</p>
          <h2 class="mb-5 text-3xl text-blue-dark"><b>{{complaintCode}}</b></h2>
          <a-button @click="$router.go(-2)" type="primary" shape="round" :block="true" >
              VOLVER AL INICIO
            </a-button>
        </div>
        <div v-if="type === 'close' " class="w-4/5 mx-auto">
          <h2 class="mb-3 text-2xl text-blue-dark"><b>Su queja se ha cancelado de manera exitosa</b></h2>
          <p class="my-5 text-sm">
            La interposición de queja con el número radicado no. <b>{{complaintCode}}</b> ha sido retirada exitosamente de nuestra plataforma Smart Supervision.
            <br><br>
            En caso de querer realizar una nueva queja, seleccione la opción de interponer nueva queja en nuestra bandeja de inicio.
          </p>
          <a-button @click="$router.push('/dashboard/complaints/search')" type="primary" shape="round" :block="true" >
              VOLVER AL INICIO
            </a-button>
        </div>
    </Wrapper>
  </div>
</template>

<script>
export default {
  computed:{
    type: function(){
      return this.$route.query.type || ''
    },
    complaintCode: function(){
      return this.$route.query.complaintCode || ''
    }
  }
}
</script>

<style>

</style>